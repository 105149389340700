import React, {Fragment, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Button, Switch, TextField} from "@material-ui/core";
import HttpClient from "../../utils";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";

function Users(props) {

    const [usuarios,setUsuarios]=useState([])
    const [edit,setEdit]=useState(false)
    const nuevo={"id":0,"name":"","email":"","is_admin":false,"password":"","confirm":""}
    const err={"name":"","email":"","password":"","confirm":""}
    const [user,setUser]=useState(nuevo)
    const [errores,setErrores]=useState(err);
    const [admin,setAmin]=useState(false)

    const cargarUsuarios=()=> {
        document.getElementById("")
        HttpClient.post('/user/list').then((result) => {
            setUsuarios(result.data.data)
        }).catch(() => {
            toast.error("Error al Cargar Usuarios")
        })
    }

    const grabarUser=()=>{
        setErrores(err)
        if(user.id===0) {
            HttpClient.post('/user/create', user).then((result) => {
                cargarUsuarios()
                setEdit(false)
            }).catch((e) => {
                setErrores(e.response.data[0])
                toast.error("Error al Crear Usuarios")
            })
        }else{
            HttpClient.post('/user/update', user).then((result) => {
                cargarUsuarios()
                setEdit(false)
            }).catch((e) => {
                setErrores(e.response.data[0])
                toast.error("Error al Actualizar Usuarios")
            })
        }
    }

    const valida=()=>{
        if (user.password !== user.confirm) {
            setErrores({ ...errores, "password": "El Password no coincide con la Confirmacion"});
        } else {
            setErrores({ ...errores, "password": ""});
        }
    }


    useEffect(() => {
        cargarUsuarios()
    }, []);
    const cerrarDialog=()=>{
        setEdit(false)
    }

    const handleEditar=(row)=>{
        setErrores(err)
        setAmin(false)
        let valor=false

        if(row.is_admin===1)
            valor=true;
        setUser({"id":row.id,"name":row.name,"email":row.email,"is_admin":valor,"password":"","cpnfirm":""})
        setEdit(true);
    }

    const nuevoUsuario=()=>{
        setErrores(err)
        setAmin(true)
        setUser(nuevo)
        setEdit(true)
    }

    const handleChange = (event) => {
        let valor=false;
        if(event.target.name==='is_admin') {
            valor = event.target.checked;
        }else{
            valor=event.target.value;
        }
        let row=user;
        row[event.target.name]=valor;
        setUser(row)
        valida()
    };

//    cargarUsuarios()
    return (
        <Fragment>
            <ToastContainer />
            <Paper >
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre)</TableCell>
                            <TableCell align="center">Correo Electónico</TableCell>
                            <TableCell align="center">Es Administrador</TableCell>
                            <TableCell align="right">Editar</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usuarios.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>

                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">
                                    {row.is_admin===1?"Si":"No"}
                                </TableCell>
                                <TableCell align="right"><Button color="primary" variant="contained" onClick={()=>handleEditar(row)} >Editar</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box xs={12} textAlign={"right"}>
                    <Button variant={"contained"} color={"primary"} size={"large"} onClick={nuevoUsuario} style={{margin:'20px 20px 20px 20px'}}>Nuevo Usuario</Button>
                </Box>
            </Paper>
            <Dialog aria-labelledby="simple-dialog-title" open={edit}  maxWidth={"xl"} >
                <DialogTitle id="simple-dialog-title">Edición de Usuario</DialogTitle>
                <Box>
                    <Box p={1}>
                        <TextField variant={"outlined"} fullWidth  name="name"  onChange={handleChange} value={user.name} helperText={errores.name} label="Nombre"/>
                    </Box>
                    <Box p={1}>
                        <TextField variant={"outlined"} fullWidth p={1} m={10} name="email" onChange={handleChange} value={user.email} helperText={errores.email} label="Correo Electrónico"   />
                    </Box>
                    <Box p={1} textAlign={"right"}>
                        <FormControlLabel disabled={admin} control={<Switch checked={user.is_admin} name="is_admin" onChange={handleChange}/>} label={"Administrador"} id="admin" />
                    </Box>
                    <Box p={1} >
                        <TextField variant={"outlined"} fullWidth style={{width:"50%"}} type={"password"} helperText={errores.password} name="password"  onChange={handleChange}  label="Constaseña" />
                        <TextField variant={"outlined"} fullWidth style={{width:"50%"}} type={"password"} helperText={errores.confirm} name="confirm"  onChange={handleChange}  label="Confirmación Constaseña" />
                    </Box>
                    <Box p={1} textAlign="right">
                        <Button onClick={cerrarDialog} variant={"contained"} color={"secondary"} size={"large"}>Cancelar</Button> <Button onClick={grabarUser} variant={"contained"} color={"primary"} size={"large"}>Grabar</Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    );
}



export default Users;