import React, {useState, Fragment, useEffect} from "react";
import {TextField,FormControlLabel,Checkbox,Button} from "@material-ui/core";
import HttpClient from "../../utils/HttpClient";
import {toast} from "react-toastify";
import {TableCell,Table,TableRow,TableBody} from "@material-ui/core";
import {Redirect} from "react-router-dom";
import { Grid } from '@material-ui/core';
const Inicio=()=>{

    const [componentes,setComponentes]=useState([])
    const [status,setStatus]=useState(false)
    const [redirect,setRedirect]=useState(false)
    const cargarComponentes=()=> {
        HttpClient.post('/componentes/list').then((result) => {
            for(let i=0;i<result.data.data.length;i++){
                result.data.data[i].seleccionado=false
            }
            setComponentes(result.data.data)
        }).catch((e) => {
            toast.error("Error al Cargar Componentes")
        })
    }
    useEffect(()=>{
        cargarComponentes()
    },[])

    const handleChange=(event)=>{

        componentes[event.target.value].seleccionado=!componentes[event.target.value].seleccionado

        setStatus(!status)
    }
if(redirect===true)
   return (
       <Redirect to={{"pathname":"/mantenimientocapturab","state":componentes}} ></Redirect>
   )
else
    return (
        <Fragment>
            <Grid container spacing={2}>
            <Grid item xs={12} >
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>CAPTURA DE </TableCell>
                    </TableRow>
                    {
                        componentes.map((row,key)=>{
                            return (<TableRow key={key} >
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={row.seleccionado}
                                                    name="checkedB"
                                                    color="primary"
                                                    onChange={handleChange}
                                                    value={key}

                                                />
                                            }
                                            label={row.descripcion}
                                        />

                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
                Número de Elementos a Capturar<TextField type="number"   inputProps={{min: 0, style: { textAlign: 'center' }}}value="1"/>

            </Grid>
            <Grid item xs={12}>
            <Button color={"primary"}   variant={"contained"} onClick={()=>setRedirect(true)} style={{margin:'20px'}}>Aceptar</Button>
            </Grid>
            </Grid>
        </Fragment>
    )
}
export default Inicio;