//region components /////
import React from 'react';
import Menu from '../components/menu';

//endregion components /////

const MainLayout = ({ children, ...restProps }) => {
    return(
        <Menu titulo={restProps.titulo}>

            <> {/*CONTAINER*/}
                        {children}
            </>{/*CONTAINER*/}
        </Menu>
    );
}

export default MainLayout;
