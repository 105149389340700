import React, {Fragment, useState} from "react";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import {Button} from "@material-ui/core";
import HttpClient from "../../utils/HttpClient";
import {toast} from "react-toastify";


const Itemmantenimientocorrectivo=(props)=>{
    const [mant]=useState(props.mantenimiento)


    return (
        <Fragment>
            <Box borderTop={1} borderColor="grey.500">
                <Grid container spacing={2} style={{padding:'10px'}}>
                    <Grid item xs={1}>
                        <Button variant={"contained"} color={"secondary"}  onClick={() => {

                            HttpClient.post('/borrar/'+mant.id).then((result) => {
                                alert("Se Elimino Correctamente el Mantenimiento")
                                window.location.reload();
                            }).catch((e) => {
                                alert("Error al Eliminar Mantenimiento")
                            })
                        }}
                        >Borrar</Button>
                    </Grid>
                    <Grid item xs={1}>
                        {mant.id}
                    </Grid>
                    <Grid item xs={1}>
                        {mant.fecha}
                    </Grid>
                    <Grid item xs={4}>
                        {mant.planta}
                    </Grid>
                    <Grid item xs={4}>
                        {mant.equipo}
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant={"contained"} color={"primary"}  onClick={() => {
                            //window.open( 'https://apimantenimiento.industrialcom.com.mx/pdf?id='+mant.id,'_blank');
                            //window.open( 'http://icom.test/pdfcorrectivo?id='+mant.id,'_blank');
                            window.open( '/rcorrectivopdf?id='+mant.id,'_blank');
                        }}
                        >Previo</Button>
                        <Button variant={"contained"} color={"primary"}  onClick={() => {
                            window.open( 'https://apimantenimiento.industrialcom.com.mx/pdf?id='+mant.id,'_blank');
                            //window.open( 'http://icom.test/pdfcorrectivo?id='+mant.id,'_blank');
                            }}
                         >Descargar</Button>

                    </Grid>

                </Grid>
            </Box>
        </Fragment>
    );
}
export default Itemmantenimientocorrectivo;