import React from 'react'

import {PublicRoute} from '../routes';
import EmptyLayout from '../layots/EmptyLayout';
import {Login, Logout,Validar} from '../components/login';
import MainLayout from '../layots/MainLayout';
import {BrowserRouter, Switch } from 'react-router-dom';
import {Users} from '../pages/users';
import {Componentes, ComponentesDetalles} from "../pages/Componentes";
import {Testo} from "../pages/test";
import {Inicio} from "../pages/mantenimientoCaptura";
import {MantenimientoCaptura} from "../pages/mantenimientoCaptura";
import {MantenimientoCorrectivo} from "../pages/mantenimientoCorrectivo";
import {Encuesta} from "../pages/encuesta"
import {Listado} from "../pages/reportes"
import {ListadoCorrectivo} from "../pages/reportesCorrectivo"
import ReportePdf from "../pages/reportes/ReportePdf";
import ReporteCorrectivoPdf from "../pages/reportesCorrectivo/ReporteCorrectivoPdf";

const Router=(props)=>{
        return (
            <BrowserRouter>
                    <Switch>
                            <PublicRoute path='/users' component={Users} layout={MainLayout} titulo="Edicion de Usuarios"/>
                            <PublicRoute path='/componentes' component={Componentes} layout={MainLayout} titulo="Componentes"/>
                            <PublicRoute path='/componentesdetalles' component={ComponentesDetalles} layout={MainLayout}  titulo="Componentes Detalles"/>
                            <PublicRoute path='/test' component={Testo} layout={MainLayout} titulo="Acceder"/>
                            <PublicRoute path='/login' component={Login} layout={EmptyLayout} titulo="Login"/>
                            <PublicRoute path='/mantenimientocaptura' component={Inicio} layout={MainLayout} titulo="Check List de Mantenimiento"/>
                            <PublicRoute path='/mantenimientocorrectivo' component={MantenimientoCorrectivo} layout={MainLayout} titulo="Mantenimiento Correctivo"/>
                            <PublicRoute path='/mantenimientocapturab' component={MantenimientoCaptura} layout={MainLayout} titulo="Check List de Mantenimiento" />
                            <PublicRoute path='/mantenimientocapturac' component={MantenimientoCaptura} layout={MainLayout} titulo="Check List de Mantenimiento" />
                            <PublicRoute path='/logout' component={Logout} layout={EmptyLayout} titulo="LogOut" />
                            <PublicRoute path='/encuesta' component={Encuesta} layout={MainLayout} titulo="Encuesta" />
                            <PublicRoute path='/rpdf' component={ReportePdf} layout={EmptyLayout} titulo="Reportes" />
                            <PublicRoute path='/rcorrectivopdf' component={ReporteCorrectivoPdf} layout={EmptyLayout} titulo="Reportes" />
                            <PublicRoute path='/reportes' component={Listado} layout={MainLayout} titulo="Reportes" />
                            <PublicRoute path='/reportescorrectivo' component={ListadoCorrectivo} layout={MainLayout} titulo="Reportes" />
                            <PublicRoute path='/' component={Validar} layout={EmptyLayout} titulo="LogOut" />

                    </Switch>
            </BrowserRouter>
        );
}
export default Router;