import React, {useEffect, useState} from 'react';

import HttpClient from "../../utils/HttpClient";

import {Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";

function ReporteCorrectivoPdf() {
    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("id")
    const [mantenimiento,setMantenimiento]=useState({})

    const [fotos,setFotos]=useState([])


    const procesaCadena=(cadena)=>{
        cadena=cadena.replaceAll('<p>','')
        cadena=cadena.replaceAll('</p>','')
        cadena=cadena.replaceAll('<p>','')
        cadena=cadena.replaceAll('</u>','')
        cadena=cadena.replaceAll('<u>','\n\n')
        cadena=cadena.replaceAll('<br>','\n\n')
        return cadena
    }

    const cargarMantenimiento=()=> {
        let parametro={"id":id}

        if(id!=null){
            HttpClient.post('/mantenimientoCorrectivo',parametro).then((result) => {
                result.data.mantenimiento.descripcion=procesaCadena(result.data.mantenimiento.descripcion)
                result.data.mantenimiento.acciones=procesaCadena(result.data.mantenimiento.acciones)
                result.data.mantenimiento.notas=procesaCadena(result.data.mantenimiento.notas)
                setMantenimiento(result.data.mantenimiento)
                setFotos(result.data.fotos)
            }).catch((e) => {

            })
        }else{

        }
    }
    useEffect(()=>{
        cargarMantenimiento()
    },[])

    Font.register({
        family: 'Oswald',
        src: '/fonts/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    Font.register({
        family: 'helvetica-bold',
        src: '/fonts/Helvetica-Bold.ttf'
    });


    Font.register({
        family: 'helvetica-italic',
        src: '/fonts/Helvetica-Oblique.ttf'
    });
    Font.register({
        family: 'helvetica-bold-italic',
        src: '/fonts/Helvetica-BoldOblique.ttf'
    });


    const styles = StyleSheet.create({

        helveticabold:{
            fontFamily:"helvetica-bold",


        },
        helveticaitalic:{
            fontFamily:"helvetica-italic",
        },
        helveticabolditalic:{
            fontFamily:"helvetica-bold-italic",
        },

        pageBackground: {
            padding:10,
            position: 'absolute',
            display: 'block',
            width: '632px',
            height: '792px',

        },
        body: {
            paddingTop: 0,
            paddingBottom: 45,
            paddingHorizontal: 35,

        },
        title: {
            fontSize: 24,
            textAlign: 'center',
            fontFamily:'Oswald'
        },
        author: {
            fontSize: 12,
            textAlign: 'center',
            marginBottom: 40,
        },
        subtitle: {
            fontSize: 18,
            margin: 12,

        },
        text: {
            margin: 12,
            fontSize: 14,
            textAlign: 'justify',

        },
        image: {
            marginVertical: 15,
            marginHorizontal: 100,
        },
        header: {
            fontSize: 12,
            marginBottom: 0,
            textAlign: 'center',
            color: 'grey',
            paddingTop:10
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
            paddingBottom: 10
        },
        tablep: {
            backgroundImage: 'url("/img/b.jpg")',
            paddingTop: '5px',
            display: "table",
            width: "auto",
            fontSize:'10px'
        },
        tableRowp: {
            flexDirection: "row",
            padding:"4px"
        },
        tableRowpg: {
            margin: "auto",
            flexDirection: "row",
            backgroundColor:"#EEE",
            padding:"4px"
        },
        tableColp: {
            width: "10%",
            textAlign:'center'
        },
        tableColfirma: {
            width: "50%",
            textAlign:'center'
        },
        tableColp30: {
            width: "30%",
        },
        tableColp50: {
            width: "150px",

        },
        tableCellp: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10
        },
        table: {
            display: "table",
            border: '3px solid orange',
            borderRadius: '20px',
            padding:'20px',
            width: "auto",
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },

        tableCol: {
            width: "70%",
        },
        tableColR: {
            width: "1%",
        },
        tableCell: {
            margin: "auto",
            marginTop: 5,
            fontSize: 10
        },
        tableRowimg: {

            flexDirection: "row",
            height: '320px',

        },
        tableCellimg: {
            margin: "auto",
            paddingLeft:0,
            fontSize: 8,
            border: '3px solid orange',
            borderRadius: '20px',
            width:'220px',
            height: '220px',
        },
        tableColimg: {
            width: "50%",

        },


    });
    const PDF=()=>{

        return (
            <Document >
                <Page style={styles.body} size="letter">
                    <Image style={styles.header} src="/img/hcorrectivo.jpg" fixed />
                    <View style={{marginLeft:50,marginRight:50}}>
                        <View style={{textAlign:"right",fontSize:12}}>
                            <Text style={styles.helveticabold}>Fecha: {mantenimiento.fecha}{"\n"}{mantenimiento.localidad}</Text>
                        </View>
                        <View style={{textAlign:"left",fontSize:12}}>
                            <Text><Text style={styles.helveticabold}>Empresa:</Text> {mantenimiento.empresa}{"\n"}
                                <Text style={styles.helveticabold}>Planta:</Text> {mantenimiento.planta}{"\n"}
                                <Text style={styles.helveticabold}>Equipo:</Text> {mantenimiento.equipo}{"\n"}
                                <Text style={styles.helveticabold}>Serie:</Text> {mantenimiento.serie}{"\n"}
                                <Text style={styles.helveticabold}>Área:</Text> {mantenimiento.area}{"\n"}
                                <Text style={styles.helveticabold}>Técnico:</Text> {mantenimiento.tecnico}{"\n\n\n"}
                            </Text>
                        </View>
                        <View style={{fontSize:12}}>
                            <Text style={styles.helveticabold}>Descripción:</Text>
                            <Text>{mantenimiento.descripcion}{"\n\n\n"}</Text>
                        </View>
                        <View style={{fontSize:12}}>
                            <Text style={styles.helveticabold}>Acciones Realizadas:</Text>
                            <Text>{mantenimiento.acciones}{"\n\n\n"}</Text>
                        </View>
                        <View style={{fontSize:12}}>
                            <Text style={styles.helveticabold}>NOTAS OBSERVADAS DEL EQUIPO POR PARTE DEL TÉCNICO.</Text>
                            <Text style={{border:"1px solid #000",padding:10,marginTop:10}}>{mantenimiento.notas}{"\n"}</Text>
                            <Text>{"\n\n\n\n\n\n"}</Text>
                        </View>
                        <View style={{textAlign:"center",fontFamily:"helvetica-bold",fontSize:12}}>
                            <Text >{mantenimiento.tecnico}</Text>
                            <Text style={{color:"darkred"}}>{mantenimiento.puesto}{"\n"}</Text>
                        </View>
                    </View>
                    <Image style={styles.pageNumber} src="/img/fcorrectivo.jpg" fixed />
                </Page>
                <Page size="letter">
                    <Image style={styles.header} src="/img/hcorrectivo.jpg" fixed />
                    <View style={{fontSize:12}}>
                        <View style={{marginLeft:40}}>
                            <Text style={styles.helveticabold}>Evidencias </Text>
                        </View>
                        <View style={styles.tablep} >
                            {
                                fotos.map((f,k)=>{
                                    if(k%2)
                                        return
                                    var of=""
                                    if(k<fotos.length-1) {
                                        of = fotos[k + 1]
                                    }
                                    return (
                                        <View style={styles.tableRowimg} key={k}>
                                            <View  style={styles.tableColimg}>
                                                <View  style={styles.tableCellimg}>
                                                    <Image src={f} key={k} style={{maxHeight:220,padding:10}} />
                                                </View>
                                            </View>
                                            {
                                                of!=""?<View style={styles.tableColimg}><View style={styles.tableCellimg}><Image src={of} key={k} style={{maxHeight: 220, padding: 10}}/></View></View>:<View></View>
                                            }
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                    <Image style={styles.pageNumber} src="/img/fcorrectivo.jpg" fixed />
                </Page>
            </Document>
        )

    }

    return (
        <PDFViewer  style={{width:'100vw',height:'100vh'}} >
<PDF />
        </PDFViewer>
    );

};

export default ReporteCorrectivoPdf;
