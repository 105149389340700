import React, {Fragment, useEffect, useState} from "react";
import HttpClient from "../../utils/HttpClient";
import {toast} from "react-toastify";

import { TextField} from "@material-ui/core";
import Reactivo from "./Reactivo";
import Button from "@material-ui/core/Button";

const Encuesta=()=>{
    const [encuesta,setEncuesta]=useState([]);
    const [comentario,setComentario]=useState('');
    const cargarEncuesta=()=> {
        HttpClient.post('/encuesta/list').then((result) => {
            result.data.map((row)=>{
                row.valor=0
            });
            result.data.comentario=comentario
            setEncuesta(result.data)


        }).catch((e) => {
            alert("Error")
            toast.error("Error al Cargar Componentes")
        })
    }

    useEffect(() => {
        cargarEncuesta()
    }, []);
    const handleChange=(evt)=>{
        encuesta.comentario=evt.target.value
        setComentario(evt.target.value)
    }
    return (
        <Fragment>
            {
                encuesta.map((reactivo,key)=>(
                    <Reactivo reactivo={reactivo} key={key}/>
                ))
            }
            <TextField fullWidth variant={"outlined"} name={"comentario"} label={"Comentario"} value={encuesta.comentario} onChange={handleChange}  multiline rows={3}   InputLabelProps={{
                shrink: true,
            }} />
            <hr />
            <Button color={'primary'} variant={"contained"}>Enviar</Button>
        </Fragment>
    );
};
export default Encuesta;