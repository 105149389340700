//region components //////
import React, { Fragment ,useEffect } from 'react';
import {Route, withRouter} from 'react-router-dom';
//import { Redirect } from 'react-router-dom';
import HttpClient from '../utils';

//endregion components //////


const PrivateRoute = ({ component: Component, layout:Layout, path, ...rest }) => {
  
  //const [redirect, setRedirect] = React.useState('');
  
  useEffect(() =>  {
    HttpClient.post('/user').then((response)=>{
    }).catch((error)=>{
      alert(JSON.stringify(error));
    });
  });
    return(      
      <Fragment>
          {//redirect
             }
      <Route path={path} {...rest} render={props =>
        <Layout>
          <Component {...props} />
        </Layout>
        }
      />
      </Fragment>
    );
  
  }

export default withRouter(PrivateRoute);
