import React, {useState} from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

function Test (props) {
    const [cam,setCam]=useState(true)

    function handleTakePhoto (dataUri) {
        // Do stuff with the photo...
        console.log(dataUri);
        setCam(false)
    }

        if(cam)
            return (
                <Camera
                    onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                />
            );


}

export default Test;