import {Router} from './routes';
import {Fragment} from "react";
function App() {
    return (
        <Fragment>
            <Router />
        </Fragment>
    );
}
export default App;
