import React, {Fragment, useEffect, useState} from "react";
import HttpClient from "../../utils/HttpClient";
import {toast} from "react-toastify";

import { TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Itemmantenimiento from "./Itemmantenimiento";

const Listado=()=>{
    const [buscar,setBuscar]=useState("")
    const [mantenimientos,setMantenimientos]=useState([])
    const [pagina,setPagina]=useState(1);
    const [refresh,setRefresh]=useState(false)
    const cargarMantenimientos=()=> {
        HttpClient.post('/listamantenimientos?buscar='+buscar+'&page='+pagina).then((result) => {
            setMantenimientos(result.data.data)
            setRefresh(!refresh);
        }).catch((e) => {
            alert("Error")
            toast.error("Error al Cargar Mantenimientos")
        })
    }

    useEffect(() => {
        cargarMantenimientos()
    }, [pagina]);

    const handleChange=(evt)=>{
        setBuscar( evt.target.value)

    }



    return (
        <Fragment>
            <TextField fullWidth variant={"outlined"} name={"buscar"} label={"Buscar"} value={buscar} onChange={handleChange} type="text"  InputLabelProps={{
                shrink: true,
            }}  onKeyPress={(event) => {
                if (event.key === "Enter") {
                    setPagina(1)
                    cargarMantenimientos()
                }
            }}/>
            {
             mantenimientos.map((mantenimiento)=>(
                 <Itemmantenimiento mantenimiento={mantenimiento} key={mantenimiento.id} />
             ))
            }
            <hr />
            <Button color={'primary'} variant={"contained"} onClick={()=>{
                if(pagina>1)
                    setPagina(pagina-1)
            }
            }>Anterior</Button>
            <Button color={'primary'} variant={"contained"} onClick={()=>{
                setPagina(pagina+1)
            }
            }>Siguiente</Button>

        </Fragment>
    );
};
export default Listado;