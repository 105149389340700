import React, {Fragment, useState} from "react";
import Box from "@material-ui/core/Box";
import {Grid, Radio, TextField} from "@material-ui/core";
const Reactivo=(props)=>{
    const [cd,setCd]=useState(props.reactivo)
    const [refresh,setRefresh]=useState(false)
    const handleChange=(evt)=>{
        cd.valor=evt.target.value
        setRefresh(!refresh)
    }
    return (
        <Fragment>
            <Box borderTop={1} borderColor="grey.500">
                <Grid container spacing={2} style={{padding:'10px'}}>
                    <Grid item xs={4}>
                        {cd.reactivo}
                    </Grid>

                    <Grid item xs={2} >
                        <Radio
                            checked={props.reactivo.valor==1}
                            onChange={handleChange}
                            value="1"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'E'}}
                            size="small"
                        />MALO
                    </Grid>
                    <Grid item xs={2} >
                        <Radio
                            checked={props.reactivo.valor==2}
                            onChange={handleChange}
                            value="2"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'E'}}
                            size="small"
                        />REGULAR
                    </Grid>
                    <Grid item xs={2} >
                        <Radio
                            checked={props.reactivo.valor==3}
                            onChange={handleChange}
                            value="3"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'E'}}
                            size="small"
                        />EXCELENTE
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}
export default Reactivo;