import React, {useEffect, useState} from 'react';
import {Fragment} from "react";
import HttpClient from "../../utils/HttpClient";
import {toast, ToastContainer} from "react-toastify";
import {Table,TableRow,TableCell,TableBody} from "@material-ui/core";
import {Button, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import {Redirect} from "react-router-dom";
const Componentes = props => {
    const [componentes,setComponentes]=useState([])
    //const [id,setId]=useState(0)
    const [edit,setEdit]=useState(false)
    const nuevo={"id":0,"descripcion":""}
    const err={"descripcion":""}
    const [componente,setComponente]=useState(nuevo)
    const [errores,setErrores]=useState(err)
    const [redirect,setRedirect]=useState('')
    var inicio
    const cargarComponentes=()=> {
        HttpClient.post('/componentes/list').then((result) => {
            setComponentes(result.data.data)
        }).catch((e) => {

            toast.error("Error al Cargar Componentes")
        })
    }

    const grabarComponente=()=>{
        setErrores(err)
        if(componente.id===0) {
            HttpClient.post('/componentes/create', componente).then((result) => {
                cargarComponentes()
                setEdit(false)
            }).catch((e) => {
                setErrores(e.response.data[0])
                toast.error("Error al Crear Componente")
            })
        }else{
            HttpClient.post('/componentes/update', componente).then((result) => {
                cargarComponentes()
                setEdit(false)
            }).catch((e) => {
                setErrores(e.response.data[0])
                toast.error("Error al Actualizar Componente")
            })
        }
    }

    const grabarOrden=()=>{
        let elementos=document.getElementsByClassName('drag-drop-zone')
        let x=[];
        for(let i=0;i<elementos.length;i++)
            x.push(elementos[i].id)
        HttpClient.post('/componentes/order', {id:x}).then((result) => {
            cargarComponentes()
            setEdit(false)
            toast.success("Se Ordeno Correctamente")
        }).catch((e) => {
            toast.error("Error al Ordenar Componente")
        })
    }
    useEffect(() => {
        cargarComponentes()
    }, []);
    const cerrarDialog=()=>{
        setEdit(false)
    }

    const handleEditar=(row)=>{
        setErrores(err)
        setComponente({"id":row.id,"descripcion":row.descripcion})
        setEdit(true);
    }

    const nuevoComponente=()=>{
        setErrores(err)
        setComponente(nuevo)
        setEdit(true)
    }

    const handleChange = (event) => {
        let row=componente;
        row[event.target.name]=event.target.value
        setComponente({...row})

    };

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = e => {
        if(e.target.closest('.drag-drop-zone').id===inicio.id)
            return;
       inicio.remove()
        e.target.closest('.drag-drop-zone').before(inicio)
        e.preventDefault();
        e.stopPropagation();

        let renglones=document.getElementsByClassName("drag-drop-zone")
        for(let i=0;i<renglones.length;i++){
            if(i%2) {
                renglones[i].setAttribute("style","background-color:#FFF")
            }else{
                renglones[i].setAttribute("style","background-color:#EEE")
            }
        }
    };

    const dragStart=(e)=>{
        inicio=e.target

    }


    const handleEditarCaracteristica=(row)=>{
        //setId(row.id)
        setComponente(row);
        setRedirect('/componentesdetalles')
    }

    if(redirect!=='') {
        return (
            <Redirect to={{"pathname":redirect,"state":{"id":componente.id,"descripcion":componente.descripcion}}} ></Redirect>
        )
    }
    else
        return (
            <Fragment>
                <ToastContainer />
                <Table>

                    <TableRow>
                        <TableCell  align="center">Descripción</TableCell>
                        <TableCell  align="center">Botones</TableCell>
                    </TableRow>
                    <TableBody>
                        {componentes.map((row,key) => (
                            <TableRow  key={row.id} id={row.id} draggable onDragStart={dragStart} className={'drag-drop-zone'}
                                       onDrop={e => handleDrop(e)}
                                       onDragOver={e => handleDragOver(e)}
                                       onDragEnter={e => handleDragEnter(e)}
                                       onDragLeave={e => handleDragLeave(e)}
                                       style={key%2?{backgroundColor:"#FFF"}:{backgroundColor:"#EEE"}}
                            >
                                <TableCell >
                                    {row.descripcion}
                                </TableCell>

                                <TableCell  align="center" id={row.id}>
                                    <Button id={row.id} color="primary" variant="contained" onClick={()=>handleEditar(row)} >Editar</Button> <Button id={row.id} color="primary" variant="contained" onClick={()=>handleEditarCaracteristica(row)} >Detalles de caracteristica</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br />
                <Box xs={12} textAlign={"right"}>
                    <Button variant={"contained"} color={"primary"} size={"large"} onClick={grabarOrden}>Grabar Orden</Button> <Button variant={"contained"} color={"primary"} size={"large"} onClick={nuevoComponente}>Nuevo Componente</Button>
                </Box>

                <Dialog aria-labelledby="simple-dialog-title" open={edit}  maxWidth={"xl"} >
                    <DialogTitle id="simple-dialog-title">Edición de Componente</DialogTitle>
                    <Box>
                        <Box p={1}>
                            <TextField variant={"outlined"} fullWidth  name="descripcion"  onChange={handleChange} value={componente.descripcion} helperText={errores.descripcion} label="Componente"/>
                        </Box>

                        <Box p={1} textAlign="right">
                            <Button onClick={cerrarDialog} variant={"contained"} color={"secondary"} size={"large"}>Cancelar</Button> <Button onClick={grabarComponente} variant={"contained"} color={"primary"} size={"large"}>Grabar</Button>
                        </Box>
                    </Box>
                </Dialog>

            </Fragment>
        );
};
export default Componentes;
