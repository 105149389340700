import React, {Fragment, useEffect} from "react";

const Logout=()=>{

    useEffect(()=>{
        localStorage.removeItem('Token');
        window.location='/'
    },[])
    return (
        <Fragment>

        </Fragment>
    )

}

export default Logout;