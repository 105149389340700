//region components //////
import React from 'react';
import {Route, withRouter} from 'react-router-dom';
//endregion components //////

const PublicRoute = ({ component: Component, layout:Layout, path, ...rest }) => {

    return(
      <Route path={path} {...rest} render={props =>
        <Layout titulo={rest.titulo}>
          <Component {...props} titulo={rest.titulo} />
        </Layout>
        }
      />
    );
  
  }

export default withRouter(PublicRoute);
