//region components //////
import React from 'react';
//endregion components //////

const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app bk" {...restProps}>
      <> {/*CONTAINER*/}
          {children}
      </>{/*CONTAINER*/}
  </main>
);

export default EmptyLayout;
