import React,{Fragment} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HttpClient from '../../utils';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Desarrollado por BellBit para  '}
      <Link color="inherit" href="https://material-ui.com/">
      ©RootBeer Gil
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const validacion=()=>{
let username=document.getElementById('username').value;
let password=document.getElementById('password').value;

  HttpClient.post('/user/login', {
    email:username,
    password:password
  })
  .then((response)=>
  {

    let token=response.data.access_token;

      localStorage.setItem('Token',token);
      window.location.href = '/';
  })
  .catch((error)=>{
    alert("Error en Usuario o Contraseña")
    console.log(error);  
});
}


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(bk_login.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const  Login = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Fragment className={classes.paper}>
          <img alt="Logo" src="login.png" style={{width:"150px", paddingBottom:"10px"}}/>
           
        
          <Typography component="h1" variant="h5">
            Acceso al sistema
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nombre de usuario"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordar inicio de sesión"
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              onClick={()=>{validacion();}}
            >
              Iniciar sesión
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  ¿Olvidó su contraseña?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"¿No tiene una cuenta? Solicítala aquí"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </Fragment>
      </Grid>
    </Grid>
  );
}



export default Login;