import React, {Fragment, useEffect, useState} from "react";
import  { Redirect } from 'react-router-dom';
import HttpClient from "../../utils";

const Validar=()=>{
    const [redirect,setRedirect]=useState('')
    const valida= ()=> {
        HttpClient.post('/user/validar').then((result) => {
            setRedirect("/users")
        }).catch(() => {
            setRedirect('/login')
            //    toast.error("Error al Cargar Usuarios")
        })
    }
    useEffect(() => {
        valida()
    }, []);

    if(redirect!=='') {
        return (
            <Redirect to={redirect}></Redirect>
        )
    }else{
        return <Fragment>Esperando Respuesta</Fragment>
    }
}

export default Validar;