import React, {useEffect, useState} from 'react';
import {Fragment} from "react";
import HttpClient from "../../utils/HttpClient";
import {toast, ToastContainer} from "react-toastify";
import {Table,TableRow,TableCell,TableBody} from "@material-ui/core";
import {Button, Switch, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const ComponentesDetalles = props => {

    const componentes_id=props.location.state.id

    const [componentesDetalles,setComponentesDetalles]=useState([])
    const [edit,setEdit]=useState(false)
    const [requerido,setRequerido]=useState(false)
    const nuevo={"id":0,"componentes_id":props.location.state.id,"descripcion":"","requerido":false}
    const err={"descripcion":""}
    const [componenteDetalle,setComponenteDetalle]=useState(nuevo)
    const [errores,setErrores]=useState(err)
    var inicio
    const cargarComponentesDetalles=()=> {
        let parametro={"componentes_id":[componentes_id]}
        HttpClient.post('/componentesdetalles/list',parametro).then((result) => {
            setComponentesDetalles(result.data.data)
        }).catch((e) => {
            toast.error("Error al Cargar Componentes")
        })
    }

    const grabarComponenteDetalles=()=>{
        setErrores(err)

        if(componenteDetalle.id===0) {

            HttpClient.post('/componentesdetalles/create', componenteDetalle).then((result) => {
                cargarComponentesDetalles()
                setEdit(false)
            }).catch((e) => {

                setErrores(e.response.data[0])
                toast.error("Error al Crear Componente")
            })
        }else{
            HttpClient.post('/componentesdetalles/update', componenteDetalle).then((result) => {
                cargarComponentesDetalles()
                setEdit(false)
            }).catch((e) => {
                alert(JSON.stringify(e.response.data))
                setErrores(e.response.data[0])
                toast.error("Error al Actualizar Componente")
            })
        }
    }

    const grabarOrden=()=>{
        let elementos=document.getElementsByClassName('drag-drop-zone')
        let x=[];
        for(let i=0;i<elementos.length;i++)
            x.push(elementos[i].id)
        x={id:x,"componentes_id":componentes_id}
        HttpClient.post('/componentesdetalles/order', x).then((result) => {
            cargarComponentesDetalles()
            setEdit(false)
            toast.success("Se Ordeno Correctamente")
        }).catch((e) => {
            alert(JSON.stringify(e.response.data))
            toast.error("Error al Ordenar ComponenteDetalle")
        })
    }
    useEffect(() => {
        cargarComponentesDetalles()
    }, []);
    const cerrarDialog=()=>{
        setEdit(false)
    }

    const handleEditar=(row)=>{
        setErrores(err)
        setRequerido(false)
        let valor=false
        if(row.requerido===1)
            valor=true;
        setComponenteDetalle({"id":row.id,"componentes_id":componentes_id,"descripcion":row.descripcion,"requerido":valor})
        setEdit(true);
    }

    const nuevoComponenteDetalle=()=>{
        setErrores(err)
        setComponenteDetalle(nuevo)
        setEdit(true)
    }


    const handleChange = (event) => {


        let valor=false;
        if(event.target.name==='requerido') {
            valor = event.target.checked;
        }else{
            valor=event.target.value;
        }
        let row=componenteDetalle
        row[event.target.name]=valor
        setComponenteDetalle({...row})

    };

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = e => {
        if(e.target.closest('.drag-drop-zone').id===inicio.id)
            return;
        inicio.remove()
        e.target.closest('.drag-drop-zone').before(inicio)
        e.preventDefault();
        e.stopPropagation();
        let renglones=document.getElementsByClassName("drag-drop-zone")
        for(let i=0;i<renglones.length;i++){
            if(i%2) {
                renglones[i].setAttribute("style","background-color:#FFF")
            }else{
                renglones[i].setAttribute("style","background-color:#EEE")
            }
        }
    };

    const dragStart=(e)=>{
        inicio=e.target

    }

    return (
        <Fragment>
            <ToastContainer />
            <h1 >Editando Componente </h1>
            <h1 style={{color:'#00F'}}>{props.location.state.descripcion}</h1>
            <Table>

                <TableRow>
                    <TableCell  align="center">Descripción</TableCell>
                    <TableCell  align="center">Requerido</TableCell>
                    <TableCell  align="center">Botones</TableCell>
                </TableRow>
                <TableBody>
                    {componentesDetalles.map((row,key) => (
                        <TableRow key={row.id} id={row.id} draggable onDragStart={dragStart} className={'drag-drop-zone'}
                                  onDrop={e => handleDrop(e)}
                                  onDragOver={e => handleDragOver(e)}
                                  onDragEnter={e => handleDragEnter(e)}
                                  onDragLeave={e => handleDragLeave(e)}
                                  style={key%2?{backgroundColor:"#FFF",width:"100%"}:{backgroundColor:"#EEE",width:"100%"}}
                        >
                            <TableCell>
                                {row.descripcion}
                            </TableCell>
                            <TableCell align={"center"}>
                                {row.requerido?'Si':'No'}
                            </TableCell>
                            <TableCell align="center" id={row.id}>
                                <Button id={row.id} color="primary" variant="contained" onClick={()=>handleEditar(row)} >Editar</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <br />
            <Box xs={12} textAlign={"right"}>
                <Button variant={"contained"} color={"primary"} size={"large"} onClick={grabarOrden}>Grabar Orden</Button> <Button variant={"contained"} color={"primary"} size={"large"} onClick={nuevoComponenteDetalle}>Nuevo Componente Detalle</Button>
            </Box>

            <Dialog aria-labelledby="simple-dialog-title" open={edit}  maxWidth={"xl"} >
                <DialogTitle id="simple-dialog-title">Edición de Componente Detalle</DialogTitle>
                <Box>
                    <Box p={1}>
                        <TextField variant={"outlined"} fullWidth  name="descripcion"  onChange={handleChange} value={componenteDetalle.descripcion} helperText={errores.descripcion} label="Componente"/>
                    </Box>
                    <Box p={1} textAlign={"right"}>
                        <FormControlLabel disabled={requerido} control={<Switch checked={componenteDetalle.requerido} name="requerido" onChange={handleChange}/>} label={"Requerido"} id="requerido" />
                    </Box>
                    <Box p={1} textAlign="right">
                        <Button onClick={cerrarDialog} variant={"contained"} color={"secondary"} size={"large"}>Cancelar</Button> <Button onClick={grabarComponenteDetalles} variant={"contained"} color={"primary"} size={"large"}>Grabar</Button>
                    </Box>
                </Box>
            </Dialog>

        </Fragment>
    );
};
export default ComponentesDetalles;
