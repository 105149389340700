import React, {useEffect, useRef, useState} from "react";
import {Fragment} from "react";
import {Switch, Table, TableRow, TableCell, TextField, TableBody} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SignatureCanvas from 'react-signature-canvas'
import HttpClient from "../../utils/HttpClient";
import {toast, ToastContainer} from "react-toastify";
import {Button} from "@material-ui/core";
import Camera from "react-html5-camera-photo";
import Compressor from 'compressorjs'
import zIndex from "@material-ui/core/styles/zIndex";
import {Redirect} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

const MantenimientoCorrectivo=(props)=>{


    const galleryItemStyle = {
        display: 'inline-block',
        margin: '5px',
        width: '24%',
        overflow: 'hidden',
        verticalAlign:'middle'
    };

    let ids=[]
    const [cam,setCam]=useState(false)
    const inputFileRef = React.useRef();
    const [refresh,setRefresh]=useState(false)
    let btnGraba = false
    const [espera,setEspera]=useState(false)
    const [fotos,setFotos]=useState([])
    const nuevoMantenimiento={
        "id":"",
        "empresa":"",
        "planta":"",
        "equipo":"",
        "capacidad":"",
        "area":"",
        "serie":"",
        "tecnico":"",
        "puesto":"",
        "fecha":"",
        "localidad":"",
        "descripcion":"",
        "acciones":"",
        "notas":""

    }

    const [mantenimiento,setMantenimiento]=useState(nuevoMantenimiento)





    const base64Resize=(sourceBase64,  imagen)=> {

        var _scale = .5;
        var img = document.createElement('img');
        img.setAttribute("src", sourceBase64);
        let wh=600
        img.onload = () => {
            var canvas = document.createElement('canvas');

            if(img.width<=0 && img.height<=0){
                return;
            }
            if(img.width>=img.height){
                _scale=wh/img.width;
            }
            else{
                _scale=wh/img.height;
            }


            canvas.width = img.width * _scale;
            canvas.height = img.height * _scale;

            var ctx = canvas.getContext("2d");
            var cw = canvas.width;
            var ch = canvas.height;
            var maxW = img.width * _scale;
            var maxH = img.height * _scale;

            var iw = img.width;
            var ih = img.height;
            var scl = Math.min((maxW / iw), (maxH / ih));
            var iwScaled = iw * scl;
            var ihScaled = ih * scl;
            canvas.width = iwScaled;
            canvas.height = ihScaled;

            ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
            const newBase64 = canvas.toDataURL("image/jpeg", scl);

            let foto=newBase64;
            let x=fotos
            x.push(foto);
            setFotos(x)
            setRefresh(!refresh);
        }
    }

    const handleChangeFromFile=(event)=> {
        let file = event.target.files[0];
        if( file===undefined) {
            return
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            base64Resize(e.target.result);
        }
        new Compressor(file, {
            quality: 0.6,
            success: (compressedResult) => {

                reader.readAsDataURL(compressedResult);

            },
        });
    }


    useEffect(()=>{

        //cargarComponentesDetalles()
    },[])

    const handleChange=(evt)=>{


        setMantenimiento({ ...mantenimiento,[evt.target.name]: evt.target.value,})

    }
    const handleChangeDescripcion=(evt)=>{
        setMantenimiento({ ...mantenimiento,"descripcion": evt})
    }
    const handleChangeAcciones=(evt)=>{
        setMantenimiento({ ...mantenimiento,"acciones": evt})
    }
    const handleChangeNotas=(evt)=>{
        setMantenimiento({ ...mantenimiento,"notas": evt})
    }

    const grabarMantenimiento=()=>{
    let validado=0

        if(mantenimiento.empresa.trim()===''){
            toast.error("Debes Capturar la empresa")
            validado=1
        }
        if(mantenimiento.planta.trim()===''){
            toast.error("Debes Capturar la planta")
            validado=1
        }
        if(mantenimiento.equipo.trim()===''){
            toast.error("Debes Capturar el equipo")
            validado=1
        }
        if(mantenimiento.area.trim()===''){
            toast.error("Debes Capturar el area")
            validado=1
        }
        if(mantenimiento.serie.trim()===''){
            toast.error("Debes Capturar la serie")
            validado=1
        }
        if(mantenimiento.tecnico.trim()===''){
            toast.error("Debes Capturar el tecnico")
            validado=1
        }
        if(mantenimiento.puesto.trim()===''){
            toast.error("Debes Capturar el puesto del tecnico")
            validado=1
        }
        if(mantenimiento.fecha.trim()===''){
            toast.error("Debes Capturar la fecha")
            validado=1
        }
        if(mantenimiento.localidad.trim()===''){
            toast.error("Debes Capturar la localidad")
            validado=1
        }
        if(mantenimiento.descripcion.trim()===''){
            toast.error("Debes Capturar la descripcion")
            validado=1
        }
        if(mantenimiento.acciones.trim()===''){
            toast.error("Debes Capturar las acciones")
            validado=1
        }
        if(mantenimiento.notas.trim()===''){
            toast.error("Debes Capturar las notas")
            validado=1
        }

        if(validado==1)
            return

        setMantenimiento({ ...mantenimiento,"fotos": JSON.stringify(fotos)})
        let parametro={ "mantenimiento":mantenimiento}
        setEspera(true);
        HttpClient.post('/createCorrectivo',parametro).then((result) => {
            setEspera(false);
            toast.success("Se Grabo Correctamente el Mantenimiento")

        }).catch((e) => {
            setEspera(false);
            toast.error("Error al Grabar Mantenimiento")

        })

    }

    const modal=()=>{
        if(espera)
        return (
            <div style={{width:"100vw",height:"100vh",position:"fixed",  backgroundColor: "#AAAAAA",zIndex:100000,opacity:.5,textAlign: "center"}} onClick={()=>{alert("Nonono")}}>
                <img src="wait.gif" style={{margin:"auto"}}></img>
            </div>
        )
    }
    const changeSwitch=()=>{
        setMantenimiento({ ...mantenimiento,['equipo_seguro_operar']:!mantenimiento.equipo_seguro_operar,})
    }

    const agregarFotoNueva=()=>{
        let x=fotos
        x.push("Algo");
        setFotos(x)
        setRefresh(!refresh)
    }
    const borrar=(i)=>{

        let x=fotos.splice(i,1)
        //setFotos(x)
        setRefresh(!refresh)
    }

    if (cam)
        return (
            <Camera
                onTakePhoto={(dataUri) => {
                    mantenimiento.foto=dataUri
                    setCam(false)
                }}
            />
        );
    return (

        <Fragment>
            <ToastContainer />

            {modal()}
            <h1>Captura Mantenimiento </h1>
            <Table width={"100%"} s={5}>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"empresa"} label={"Empresa"} value={mantenimiento.empresa} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"equipo"} label={"Equipo"} value={mantenimiento.equipo} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"capacidad"} label={"Capacidad"} value={mantenimiento.capacidad} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"planta"} label={"Planta"} value={mantenimiento.planta} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"area"} label={"Area"} value={mantenimiento.area} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"marca"} label={"Marca"} value={mantenimiento.marca} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"serie"} label={"Serie"} value={mantenimiento.serie} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"tecnico"} label={"Tecnico"} value={mantenimiento.tecnico} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"puesto"} label={"Puesto"} value={mantenimiento.puesto} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"fecha"} label={"Fecha"} value={mantenimiento.fecha} onChange={handleChange} type="date"  InputLabelProps={{
                                shrink: true,
                            }}/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TextField fullWidth variant={"outlined"} name={"localidad"} label={"Localidad"} value={mantenimiento.localidad} onChange={handleChange}/>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>

            Descripción
            {
                //<ReactQuill theme="snow" value={mantenimiento.descripcion} name={"descripcion"} onChange={handleChangeDescripcion} />
            }
            <TextField fullWidth variant={"outlined"} name={"descripcion"} label={"descripcion"} value={mantenimiento.descripcion} onChange={handleChange} multiline={true}/>
            Acciones
            <TextField fullWidth variant={"outlined"} name={"acciones"} label={"acciones"} value={mantenimiento.acciones} onChange={handleChange} multiline={true}/>
            {
             //   <ReactQuill theme="snow" value={mantenimiento.acciones} name={"acciones"} onChange={handleChangeAcciones}/>
            }
                Notas
                <TextField fullWidth variant={"outlined"} name={"notas"} label={"notas"} value={mantenimiento.notas} onChange={handleChange} multiline={true}/>
            {
            //    <ReactQuill theme="snow" value={mantenimiento.notas} name={"notas"} onChange={handleChangeNotas} />
            }

                <Button onClick={()=>{inputFileRef.current.click()}} color={"default"}  variant={"contained"}>Agregar Imagen</Button>
            <br />

                    {
                        fotos.map((v,c)=>{
                                return <div  key={c} className="gallery-item" style={galleryItemStyle} ><img    src={v}  width="200px"  onClick={()=>{borrar(c)}}/></div>
                        })
                    }

            <input id="fotoArchivo" ref={  inputFileRef } style={{display:"none"}} type="file" onChange={handleChangeFromFile}/>
            <br />
            <Button
                onClick={grabarMantenimiento}
             variant={"contained"} color={"primary"}>Grabar Mantenimiento</Button>


        </Fragment>

    )
}

export default MantenimientoCorrectivo