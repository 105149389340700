import axios from "axios";

//axios.defaults.baseURL = "https://icom.bellbit.com/api/v1";
//axios.defaults.baseURL = "http://icom.test/api/v1";
axios.defaults.baseURL = "https://apimantenimiento.industrialcom.com.mx/api/v1";

axios.interceptors.request.use(
    (config) => {

      const token_seguridad = window.localStorage.getItem("Token");

     if (token_seguridad || config.url==='/user/login') {
        config.headers.Authorization = "Bearer " + token_seguridad;

        return config;
      }
      throw new axios.Cancel('Operation canceled by the user.');
    },
    (error) => {
      return Promise.reject(error);
    }
  );

const requestGenerico = {
  get: (url) => axios.get(url),
  post: (url, body) => axios.post(url, body),
  put: (url, body) => axios.put(url, body),
  delete: (url) => axios.delete(url),
};

export default requestGenerico;
