import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

import Box from '@material-ui/core/Box';

import Icon from "@material-ui/core/Icon";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const  Menu = ({children,titulo}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
      <Grid className={classes.root}>
        <CssBaseline />
        <AppBar
            style={{ background: '#F05D35' }}
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
        >
          <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
            >
              <MenuIcon />
            </IconButton>
            <img src={"../logo.png"} alt="Industrial COM" className="Logo"/>
            <Typography variant="h6" noWrap >
              <Box fontWeight="fontWeightLight" m={1}>
                | {titulo}
              </Box>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
        >
          <Grid className={classes.toolbar} style={{background:"#404040"}}>
            <IconButton onClick={handleDrawerClose} className="gray">
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Grid>

          <Divider />
          <List>
            <a href="/users" style={{textDecoration:"none",color:'black'}}>
              <ListItem button className="yellow">
                <ListItemIcon ><Icon className="bg-brown">people</Icon></ListItemIcon>
                <ListItemText primary='Usuarios' />
              </ListItem>
            </a>
            <a href="/componentes" style={{textDecoration:"none",color:'black'}}>
              <ListItem button className="yellow">
                <ListItemIcon ><CollectionsBookmarkIcon /></ListItemIcon>
                <ListItemText primary='Componentes' />
              </ListItem>
            </a>
            <a href="/mantenimientocaptura" style={{textDecoration:"none",color:'black'}}>
              <ListItem button className="yellow">
                <ListItemIcon><Icon className="bg-brown">exploreTwoTone</Icon></ListItemIcon>
                <ListItemText primary='Captura Mantenimiento' />
              </ListItem>
            </a>
            <a href="/mantenimientocorrectivo" style={{textDecoration:"none",color:'black'}}>
              <ListItem button className="yellow">
                <ListItemIcon><Icon className="bg-brown">exploreTwoTone</Icon></ListItemIcon>
                <ListItemText primary='Captura Correctivo' />
              </ListItem>
            </a>
            <a href="/reportes" style={{textDecoration:"none",color:'black'}}>
              <ListItem button className="yellow">
                <ListItemIcon><Icon className="bg-brown">exploreTwoTone</Icon></ListItemIcon>
                <ListItemText primary='Descargar PDF' />
              </ListItem>
            </a>
            <a href="/reportescorrectivo" style={{textDecoration:"none",color:'black'}}>
              <ListItem button className="yellow">
                <ListItemIcon><Icon className="bg-brown">exploreTwoTone</Icon></ListItemIcon>
                <ListItemText primary='Descargar PDF Correctivo' />
              </ListItem>
            </a>
          </List>
          <Divider />
          <List>
            <a href="/logout"  style={{textDecoration:"none",color:'black'}} >
              <ListItem button key='LogOut'>
                <ListItemIcon> <Icon className="bg-brown">exit_to_app</Icon></ListItemIcon>
                <ListItemText  primary='LogOut' />
              </ListItem>
            </a>
          </List>
        </Drawer>
        <main className={classes.content}>
          <Grid className={classes.toolbar} />
          <>
            {children}
          </>
        </main>
      </Grid>
  );
}
export default Menu;